import {
  CannedResponse,
  commentDoc,
  Role,
  WorkOrderForm,
  UploadDocument,
  uploadPhotoFilePath,
  WorkOrderDetail,
  FileUploadResponse
} from '../../models/work-order';
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { SupervisorService } from 'src/app/modules/oclm-supervisor/services/supervisor.service';
import { AuthenticationService } from 'src/app/core/helpers/services/authentication.service';
import { removeDuplicateObjFromArray } from '../../helpers/remove-duplicate-obj-from-array';
import { UserService } from '../../../core/Users/main-users/shared/services/user.service';
import { ActivatedRoute, Event, NavigationEnd, Params, Router } from '@angular/router';
import { MethodsForAllRoles } from '../../services/methods-for-all-roles.service';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicFieldsModel, DynamicModel } from '../../models/dynamic-model';
import { filter, finalize, switchMap, take, takeUntil } from 'rxjs/operators';
import { LoaderService } from '../../../core/helpers/services/loader.service';
import { CloseWorkOrder, Notes, Reopen, role, Save } from 'src/utils';
import { FieldAgentUserDetail } from '../../models/field-agent-user';
import { ConstantsService } from '../../services/constants.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { APIResponse, Status } from '../../models/api-response';
import { CommonService } from '../../services/common.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { MatChipInputEvent } from '@angular/material/chips';
import { environment } from 'src/environments/environment';
import { WorkOderModel } from '../../models/workOderModel';
import { ClientUser, Job, Label, LabelForWO, User } from '../../models/user';
import { Title } from '@angular/platform-browser';
import { JobList } from '../../models/job-list';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { MapsAPILoader } from '@agm/core';
import { JobService } from '../../../modules/jobs/services/customer.service';
import { customEmailValidator } from '../../utils/custom-form-validators';

@Component({
  selector: 'app-work-order-manual-agent',
  templateUrl: './work-order-manual-agent.component.html',
  styleUrls: ['./work-order-manual-agent.component.css']
})

export class WorkOrderManualAgentComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {

  chips: string[] = [];
  apiUrl = environment.apiUrl;
  applicationUrl = environment.applicationUrl;
  destroy$: Subject<boolean> = new Subject<boolean>();
  workOrderForm: FormGroup;
  ContactForm: FormGroup;
  DynamicFieldsList: DynamicFieldsModel[] = [];
  uploadBeforeDocumentComments: commentDoc[] = [];
  uploadAfterDocumentComments: commentDoc[] = [];
  uploadBeforeCommentData: commentDoc[] = [];
  uploadAfterCommentData: commentDoc[] = [];
  uploadBeforeComment: string;
  uploadAfterComment: string;
  statusList: Status[] = [];
  uploadBeforeId: string;
  uploadAfterId: string;
  bsConfig: Partial<BsDatepickerConfig>;
  fieldAgentListDetails: FieldAgentUserDetail[] = [];
  jobListDetails: JobList[] = [];
  workCategoryList: DynamicModel[] = [];
  modalRef: BsModalRef;
  message: string;
  isEditMode = false;
  isMapDisabledMode = false;
  submitted = false;
  selectedJobName = '';
  uploadBeforePhotoFilePath: uploadPhotoFilePath[] = [];
  uploadAfterPhotoFilePath: uploadPhotoFilePath[] = [];
  workOrder: WorkOderModel = new WorkOderModel();
  extLat: string;
  extLong: string;
  minDate: Date;
  maxDate: Date;
  completeByInit: Date;
  cannedResponseList: CannedResponse[] = [];
  originalWorkOrderForm: FormGroup;
  UploadedPhotoAfterIdsTemp: string[] = [];
  UploadedPhotoBeforeIdsTemp: string[] = [];
  btnText: string = Save;
  contactAddress = '';
  currentUserRole;
  WorkOrderNotesDetail: WorkOrderDetail[] = [];
  showScheduleDate = false;
  UploadedDocPathsBefore: string[] | UploadDocument[];
  UploadedDocPathsAfter: string[] | UploadDocument[];
  jobId;
  supervisorList: Role[];
  CannedResponseName: string;
  CannedResponseId;
  existingLatitude: number;
  existingLongitude: number;
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  workOrderType: string;
  city: string;
  zipCode: string;
  currentUser: User;
  WorkStateComplete: boolean;
  disableFieldAgent = true;
  WorkId: string;
  workOrderIdTemp: string;
  isLoadingCurrentLocation = false;
  readCustomerId: string;
  readAddress: string;
  readWorkCategory: string;
  createdBy: string;
  readWorkRequestID: string;
  AdditionalComment: string;
  customerId: number;
  prevCustomerId: number;
  closeWorkOrder: string = CloseWorkOrder;
  buttonText: string;
  isDisabledStatus = true;
  currentRoute: boolean;
  readCannedResponseText: string;
  isDisabledCannedResponseText = true;
  contactInfoSubmit: boolean;
  currentStatus: string;
  clientList: ClientUser[] = [];
  workOrderTypes = {
    BeforeCreationWorkOrder: 'BeforeCreationWorkOrder',
    AfterCreationWorkOrder: 'AfterCreationWorkOrder'
  };
  woExist = false;
  job: Job;
  labelDataToUpdate: LabelForWO = {
    WorkOrderId: 0, Labels: []
  };
  assignedSupervisors = [];

  @Input() existingWorkOrderDetails: WorkOrderForm;
  @Input() WorkIdVal;
  @Output() onWorkOrderUpdateResponse: EventEmitter<WorkOrderForm>;
  @Output() actionTaken: EventEmitter<string>;
  @Output() onAddressAdd: EventEmitter<string> = new EventEmitter<string>();
  @Output() onSelectedJobAdd: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('template') template: TemplateRef<any>;
  @ViewChild('contactInfo') contactInfo: TemplateRef<any>;
  @ViewChild('commentBeforeTemplate') commentBeforeTemplate: TemplateRef<any>;
  @ViewChild('commentAfterTemplate') commentAfterTemplate: TemplateRef<any>;
  @ViewChild('templateConfirm') templateConfirm: TemplateRef<any>;
  @ViewChild('search') searchElementRef: ElementRef;
  @ViewChild('confirmCreate') confirmCreateTemplate: TemplateRef<any>;
  @ViewChild('contactInfoRequiredEl') contactInfoRequiredEl: ElementRef;
  isForClient = false;
  contactFormIsValid = true;
  isForResident = false;
  private geoCoder;
  private validAddress = true;
  private routeParams: Params;
  private client: ClientUser;

  constructor(
    private modalService: BsModalService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private authService: AuthenticationService,
    private commonService: CommonService,
    private loaderService: LoaderService,
    private jobService: JobService,
    private location: Location,
    private mapsAPILoader: MapsAPILoader,
    private supervisorService: SupervisorService,
    private ngZone: NgZone,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title,
    private methodsForAllRoles: MethodsForAllRoles
  ) {
    moment().tz('America/Los_Angeles').format();
    this.bsConfig = Object.assign(
      {},
      {
        containerClass: 'theme-red',
        animated: true
      }
    );
    this.onWorkOrderUpdateResponse = new EventEmitter<WorkOrderForm>();
    this.minDate = new Date();
    this.maxDate = new Date();
    this.minDate.setDate(this.minDate.getDate());
    this.maxDate.setDate(this.maxDate.getDate() + 30);
    this.completeByInit = new Date();
    this.completeByInit.setDate(this.maxDate.getDate() + 30);
    this.actionTaken = new EventEmitter<string>();
    this.authService.currentUser.subscribe((value) => {
      this.currentUser = value;
    });
    this.title.setTitle(
      `OCLM - ${role(this.currentUser.Role)} - Create work order`
    );

    this.workOrderIdTemp = `WCD - ${moment(new Date()).format('DD/MM/YYYY')}`;
  }

  @Input() set existingRecord(val) {
    this.woExist = val;
  };

  get f() {
    return this.workOrderForm.controls;
  }

  get contactForm() {
    return this.ContactForm.controls;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      this.woExist &&
      this.buttonText !== 'fileUpload' &&
      this.buttonText !== 'notes'
    ) {
      this.createWorkOrderForm();
      this.getExistingWorkOrder();
      this.getLocationLatitudeLong();
      this.getCannedResponseTextName(
        this.existingWorkOrderDetails.CannedResponseId
      );
    }
  }

  ngOnInit() {
    this.routeParams = this.activatedRoute.snapshot.params;
    this.isForClient && this.getClientListByJobId(this.jobId);
    this.getSupervisorListByJobId();
    this.currentUserRole = this.authService?.currentLoginUserValue?.Role;
    if (this.currentUserRole === undefined) {
      this.router.navigate(['/login']);
    }
    // Create form
    this.createWorkOrderForm();
    this.creatContactForm();
    this.listenRouterEvents();
    this.getWorkCategoryList();
    this.getStatusListLocal();
    if (this.woExist) {
      this.getExistingWorkOrder();
    }

    this.getJobListByRole();
    this.getWorkOrderCompleteStatus();
    this.getLocationLatitudeLong();
  }

  ngAfterViewInit(): void {
    if (!this.woExist && this.routeParams['client']) {
      this.modalRef = this.modalService.show(this.confirmCreateTemplate, {
        class: 'modal-md'
      });
    }
    this.createOriginalContactForm();
    this.setOriginalFormValues();
    this.getUrlEnd();
  }

  confirm(): void {
    this.modalRef.hide();
  }

  getStatusListLocal() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const lan = user.LanguageName === 'English' ? 'en' : 'es';
    this.statusList = this.commonService.getStatusListLocal(lan);
  }

  getFieldAgentBySupervisorId(jobId: number) {
    this.commonService
      .getFieldAgentListByLoggedInUserAndJobIds(jobId)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        if (res.Data && res.Success && res.StatusCode === 200) {
          this.fieldAgentListDetails = res.Data;
        }
      });
  }

  autoGrowTextZone(e) {
    e.target.style.height = '0px';
    e.target.style.height = e.target.scrollHeight + 25 + 'px';
  }

  onImageDelete(docId: string) {
    this.uploadBeforeDocumentComments =
      this.uploadBeforeDocumentComments.filter((x) => x.uploadId !== docId);

    this.uploadAfterDocumentComments = this.uploadAfterDocumentComments.filter(
      (x) => x.uploadId !== docId
    );

    this.uploadBeforePhotoFilePath = this.uploadBeforePhotoFilePath.filter(
      (x) => !x.filePath.includes(docId)
    );

    this.uploadAfterPhotoFilePath = this.uploadAfterPhotoFilePath.filter(
      (x) => !x.filePath.includes(docId)
    );

    this.workOrderForm.patchValue({
      uploadBeforeDocument:
        this.uploadBeforeDocumentComments.length > 0
          ? this.uploadBeforeDocumentComments
          : '',
      uploadAfterDocument:
        this.uploadAfterDocumentComments.length > 0
          ? this.uploadAfterDocumentComments
          : ''
    });
    if (docId && this.WorkIdVal) {
      this.supervisorService
        .DeleteWorkOrderDocument(this.WorkIdVal, docId)
        .pipe(
          switchMap(() => this.getWorkOrderByWorkId()),
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
        });
    }
  }

  getWorkOrderByWorkId(): Observable<any> {
    return this.supervisorService.getWorkOrderByWorkId(this.WorkIdVal, this.workOrderType);
  }

  onImageCommentUpdated(data, workOrderType: string) {
    if (workOrderType === this.workOrderTypes.BeforeCreationWorkOrder) {
      this.uploadBeforeDocumentComments = removeDuplicateObjFromArray(this.uploadBeforeDocumentComments, data.docId);
      this.workOrderForm.patchValue({
        uploadBeforeDocument: this.uploadBeforeDocumentComments
      });
      this.uploadBeforeDocumentComments.push({
        uploadId: data.docId,
        uploadComment: data.comment
      });
    } else {
      this.uploadAfterDocumentComments = removeDuplicateObjFromArray(this.uploadAfterDocumentComments, data.docId);
      this.workOrderForm.patchValue({
        uploadAfterDocument: this.uploadAfterDocumentComments
      });
      this.uploadAfterDocumentComments.push({
        uploadId: data.docId,
        uploadComment: data.comment
      });
    }

    if (data) {
      this.supervisorService
        .EditWorkOrderComment(data.docId, data.comment)
        .subscribe(() => {
          this.supervisorService
            .getWorkOrderByWorkId(this.WorkIdVal, this.workOrderType)
            .pipe(take(1))
            .pipe(takeUntil(this.destroy$))
            .subscribe((data: APIResponse) => {
              this.getExistingWoComment(data);
              this.updateWorkOrder(data['Data']);
            });
        });
    }
  }

  updateWorkOrder(array) {
    if (array) {
      if (array.BeforeWorkOrderDocumentDetail) {
        this.UploadedDocPathsBefore = array.BeforeWorkOrderDocumentDetail;
      }

      if (array.AfterWorkOrderDocumentDetail) {
        this.UploadedDocPathsAfter = array.AfterWorkOrderDocumentDetail;
      }
      this.getBeforeFile(this.UploadedDocPathsBefore);
      this.getAfterFile(this.UploadedDocPathsAfter);
    }
  }

  saveAfterCommentWhileCreatingOrder(data): void {
    this.saveAfterComment(data.comment, data.uploadId);
  }

  saveBeforeCommentWhileCreatingOrder(data): void {
    this.saveBeforeComment(data.comment, data.uploadId);
  }

  saveBeforeComment(comment: string, uploadId: string) {
    this.uploadBeforeComment = removeDuplicateObjFromArray(this.uploadBeforeCommentData, uploadId);
    this.uploadBeforeDocumentComments = removeDuplicateObjFromArray(this.uploadBeforeDocumentComments, uploadId);
    this.workOrderForm.patchValue({
      uploadBeforeDocument: this.uploadBeforeDocumentComments
    });
    this.uploadBeforeCommentData.push({
      uploadId: uploadId,
      uploadComment: comment
    });
    this.uploadBeforeDocumentComments.push({
      uploadId: uploadId,
      uploadComment: comment
    });
    this.uploadBeforePhotoFilePath.forEach((file: uploadPhotoFilePath) => {
      if (file.filePath.split('/')[5].split('.')[0] === uploadId) {
        file.uploadComment = comment;
      }
    });
    this.modalRef ? this.modalRef.hide() : '';
    this.uploadBeforeComment = '';
  }

  toggleEdit() {
    this.isEditMode = !this.isEditMode;
    this.isMapDisabledMode = true;
  }

  updateWorkOrderStatus() {
    this.showScheduleDate = false;
    this.workOrderForm.patchValue({
      WorkStatusText: 'WorkInProgress'
    });
  }

  saveAfterComment(comment: string, uploadId: string) {
    this.uploadAfterComment = removeDuplicateObjFromArray(this.uploadAfterCommentData, uploadId);
    this.uploadAfterDocumentComments = removeDuplicateObjFromArray(this.uploadAfterDocumentComments, uploadId);
    this.workOrderForm.patchValue({
      uploadAfterDocument: this.uploadAfterDocumentComments
    });
    this.uploadAfterCommentData.push({
      uploadId: uploadId,
      uploadComment: comment
    });
    this.uploadAfterDocumentComments.push({
      uploadId: uploadId,
      uploadComment: comment
    });
    this.uploadAfterPhotoFilePath.forEach((file: uploadPhotoFilePath) => {
      if (file.filePath.split('/')[5].split('.')[0] === uploadId) {
        file.uploadComment = comment;
      }
    });
    this.modalRef ? this.modalRef.hide() : '';
    this.uploadAfterComment = '';
  }

  onSubmit() {
    return false;
  }

  setLocationName(event: KeyboardEvent | FocusEvent) {
    event.preventDefault();
    if ((event.target as HTMLInputElement).value) {
      this.workOrderForm.setValue({
        ...this.workOrderForm.value,
        Address: (event.target as HTMLInputElement).value
      });
      this.zipCode = (event.target as HTMLInputElement).value;
      this.city = (event.target as HTMLInputElement).value;
    }
  }

  getWorkOrderCompleteStatus() {
    this.commonService.workOrderStatusCompleteObs.subscribe((res) => {
      this.WorkStateComplete = res;
    });
  }

  handleCurrentLocation() {
    this.isLoadingCurrentLocation = true;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position: GeolocationPosition) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 30;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude: number, longitude: number) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results: { formatted_address: string }[], status: string) => {

        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 30;
            this.address = results[0].formatted_address;
            this.zipCode = this.address;
            this.city = this.address;
            this.workOrderForm.patchValue({
              Address: this.validAddress ? this.address : this.workOrderForm.value.Address,
              Latitude: latitude,
              Longitude: longitude
            });
            this.isLoadingCurrentLocation = false;
          } else {
            window.alert('No results found');
          }
        } else {
          window.alert('Geocoder failed due to: ' + status);
        }
      }
    );
  }

  creatContactForm() {
    this.ContactForm = this.formBuilder.group({
      ContactFirstName: [''],
      ContactLastName: [''],
      ContactPhoneNumber: [''],
      ContactEmail: ['', [customEmailValidator(), Validators.required]],
      AdditionalContactEmail: this.formBuilder.array([]),
      ContactAddress: ['']
    });

  }

  createWorkOrderForm() {
    this.workOrderForm = this.formBuilder.group({
      WorkRequestID: [null, Validators.required],
      CreatedDate: [new Date()],
      WorkStatusText: [this.currentUserRole === ConstantsService.User_Role.Admin
        ? 'Pending'
        : 'WorkInProgress', Validators.required],
      CustomerId: [null, Validators.required],
      NotesCreatedBy: [null],
      WorkCategory: [null, Validators.required],
      CompletedBy: [null, Validators.required],
      CompletedOn: [null],
      Address: ['', Validators.required],
      DynamicFields: [''],
      uploadBeforeDocument: [''],
      uploadAfterDocument: [''],
      Notes: [''],
      Latitude: [''],
      Longitude: [''],
      CreatedBy: this.isForClient ? [null, Validators.required] : [null],
      UploadedPhotoBeforeIds: [''],
      UploadedPhotoAfterIds: [''],
      CannedResponseId: [null],
      CannedResponseText: [null],
      CompletedAddress: [''],
      notifyCheck: [false],
      StartDate: [new Date()],
      EndDate: [''],
      FieldAgentId: [null],
      FieldAgentName: [''],
      assignFieldAgent: ['myself'],
      CannedResponseName: [''],
      SuperVisorId: [null],
      SuperVisorName: [''],
      CustomerName: [''],
      SaveBtnText: [''],
      TokenId: [''],
      isForClient: [false],
      isForResident: [false],
      AssignedSupervisors: [null]
    });
    this.workOrderForm.controls.CompletedBy.setValue(this.completeByInit);
    this.workOrderForm.controls.StartDate.setValue(this.completeByInit);
  }

  // For preview page
  bindValueInField() {
    // Only work in admin role
    if (this.jobId) {
      this.workOrderForm.patchValue({
        CustomerId: this.jobId,
        CannedResponseName: this.CannedResponseName
      });
    }
    this.workOrder = this.workOrderForm.value;
  }

  getExistingWorkOrder() {
    if (this.existingWorkOrderDetails) {
      this.getJobLabels(this.existingWorkOrderDetails.CustomerId);
      this.isForResident = this.existingWorkOrderDetails.IsForResident;

      if (this.existingWorkOrderDetails?.BeforeWorkOrderDocumentDetail) {
        this.UploadedDocPathsBefore =
          this.existingWorkOrderDetails?.BeforeWorkOrderDocumentDetail;
      }

      if (this.existingWorkOrderDetails?.AfterWorkOrderDocumentDetail) {
        this.UploadedDocPathsAfter =
          this.existingWorkOrderDetails?.AfterWorkOrderDocumentDetail;
      }
      if (this.existingWorkOrderDetails.AdditionalContactEmail.length > 0) {
        this.chips = this.existingWorkOrderDetails.AdditionalContactEmail;
      }

      this.title.setTitle(
        `OCLM - ${role(this.currentUser.Role)} -  WCD${
          this.existingWorkOrderDetails?.WorkId
        }`
      );
      this.workOrderForm.patchValue({
        CompletedBy: this.existingWorkOrderDetails.CompletedBy
          ? moment(this.existingWorkOrderDetails.CompletedBy).format('L')
          : this.completeByInit,
        CompletedOn:
          this.existingWorkOrderDetails.CompletedOn != null
            ? moment(this.existingWorkOrderDetails.CompletedOn).format('L')
            : null,
        WorkRequestID: this.existingWorkOrderDetails.WorkRequestID,
        CreatedDate: moment(this.existingWorkOrderDetails.CreatedDate).format(
          'L'
        ),
        CustomerId: this.existingWorkOrderDetails.CustomerId,
        WorkStatusText: this.existingWorkOrderDetails.WorkStatusText
          ? this.existingWorkOrderDetails.WorkStatusText
          : 'Pending',

        WorkCategory: this.existingWorkOrderDetails.WorkCategory,
        Notes: this.existingWorkOrderDetails.Notes,
        Address: this.existingWorkOrderDetails.Address,
        CustomerName: this.existingWorkOrderDetails.JobName,
        CreatedBy: this.existingWorkOrderDetails.CreatedBy,
        UploadedPhotoAfterIds: this.existingWorkOrderDetails
          .UploadedPhotoAfterIds
          ? this.existingWorkOrderDetails.UploadedPhotoAfterIds
          : [],

        UploadedPhotoBeforeIds: this.existingWorkOrderDetails
          .UploadedPhotoBeforeIds
          ? this.existingWorkOrderDetails.UploadedPhotoBeforeIds
          : [],
        DynamicFields: this.existingWorkOrderDetails.DynamicFields
          ? this.existingWorkOrderDetails.DynamicFields
          : '',
        uploadBeforeDocument: this.existingWorkOrderDetails.uploadBeforeDocument
          ? this.existingWorkOrderDetails.uploadBeforeDocument
          : '',
        uploadAfterDocument: this.existingWorkOrderDetails.uploadAfterDocument
          ? this.existingWorkOrderDetails.uploadAfterDocument
          : '',

        Latitude: this.existingWorkOrderDetails.Latitude,
        Longitude: this.existingWorkOrderDetails.Longitude,
        CannedResponseId: this.existingWorkOrderDetails.CannedResponseId
          ? this.existingWorkOrderDetails.CannedResponseId
          : null,
        CannedResponseName: this.existingWorkOrderDetails.CannedResponseName
          ? this.existingWorkOrderDetails.CannedResponseName
          : null,

        CompletedAddress: this.existingWorkOrderDetails.CompletedAddress
          ? this.existingWorkOrderDetails.CompletedAddress
          : '',
        StartDate: this.existingWorkOrderDetails.StartDate
          ? moment(this.existingWorkOrderDetails.StartDate).format('l')
          : new Date(),

        EndDate: this.existingWorkOrderDetails.EndDate
          ? this.existingWorkOrderDetails.EndDate
          : new Date(),
        SuperVisorId: this.existingWorkOrderDetails.AssignedSupervisors.map(i => i.Id),
        SuperVisorName: this.existingWorkOrderDetails.SupervisorName,
        FieldAgentName: this.existingWorkOrderDetails.FieldAgentName,
        FieldAgentId: this.existingWorkOrderDetails.FieldAgentId,
        isForResident: this.existingWorkOrderDetails.IsForResident,
        CannedResponseText: this.existingWorkOrderDetails.CannedResponseText
          ? this.existingWorkOrderDetails.CannedResponseText
          : '',
        AssignedSupervisors: this.existingWorkOrderDetails.AssignedSupervisors
      });

      this.assignedSupervisors = this.existingWorkOrderDetails.AssignedSupervisors

      if (
        this.currentUserRole === ConstantsService.User_Role.Admin ||
        this.currentUserRole === ConstantsService.User_Role.SuperVisor
      ) {
        this.workOrderForm.patchValue({
          SuperVisorId: this.existingWorkOrderDetails.FieldAgentId
            ? [this.existingWorkOrderDetails.FieldAgentId]
            : [this.existingWorkOrderDetails.SuperVisorId],
          isForResident: this.existingWorkOrderDetails.IsForResident ?
            this.existingWorkOrderDetails.IsForResident : false
        });
      }

      if (
        this.currentUserRole === ConstantsService.User_Role.SuperVisor &&
        this.existingWorkOrderDetails.WorkStatusText === 'UnderReview'
      ) {
        this.btnText = this.closeWorkOrder;
      }

      if (
        this.currentUserRole &&
        (this.currentUserRole === ConstantsService.User_Role.SuperVisor ||
          this.currentUserRole === ConstantsService.User_Role.Admin)
      ) {
        this.getSupervisorListByCustomerId(
          this.existingWorkOrderDetails.CustomerId
        );
      }
      if (this.existingWorkOrderDetails !== null) {
        this.ContactForm.patchValue({
          ContactFirstName: this.existingWorkOrderDetails.ContactFirstName
            ? this.existingWorkOrderDetails.ContactFirstName
            : '',
          ContactLastName: this.existingWorkOrderDetails.ContactLastName
            ? this.existingWorkOrderDetails.ContactLastName
            : '',
          ContactPhoneNumber: this.existingWorkOrderDetails.ContactPhoneNumber
            ? this.existingWorkOrderDetails.ContactPhoneNumber
            : '',
          ContactEmail: this.existingWorkOrderDetails.ContactEmail
            ? this.existingWorkOrderDetails.ContactEmail
            : ''
        });

      }
      if (this.existingWorkOrderDetails.ContactAddress) {
        this.contactAddress = this.existingWorkOrderDetails.ContactAddress;
      }
      this.setOriginalFormValues();
      if (this.existingWorkOrderDetails.CustomerId) {
        this.getFieldAgentBySupervisorId(
          this.existingWorkOrderDetails.CustomerId
        );
      }

      if (this.existingWorkOrderDetails.FieldAgentId) {
        this.workOrderForm.patchValue({
          assignFieldAgent: 'other'
        });
        this.disableFieldAgent = false;
      }

      this.WorkOrderNotesDetail = this.existingWorkOrderDetails.WorkOrderNotesDetail
        ? this.existingWorkOrderDetails.WorkOrderNotesDetail : [];
      this.showScheduleDate =
        this.existingWorkOrderDetails.WorkStatusText === 'Schedule'
          ? true
          : this.showScheduleDate;
      this.currentStatus = this.existingWorkOrderDetails.WorkStatusText;
      this.CannedResponseName = this.existingWorkOrderDetails.CannedResponseName
        ? this.existingWorkOrderDetails.CannedResponseName
        : '';
      this.latitude = this.existingWorkOrderDetails.Latitude;

      // editMode value
      this.prevCustomerId = this.existingWorkOrderDetails.CustomerId;
      this.readCustomerId = this.existingWorkOrderDetails.JobName;
      this.readAddress = this.existingWorkOrderDetails.Address;
      this.readWorkCategory = this.existingWorkOrderDetails.WorkCategory;
      this.createdBy = this.existingWorkOrderDetails.CreatedByFirstName !== null ?
        this.existingWorkOrderDetails.CreatedByFirstName + ' '
        + this.existingWorkOrderDetails.CreatedByLastName : this.existingWorkOrderDetails.ContactFirstName + ' ' + this.existingWorkOrderDetails.ContactLastName;
      this.readWorkRequestID = this.existingWorkOrderDetails.WorkRequestID;
      this.AdditionalComment = this.existingWorkOrderDetails.AdditionalComment;
      this.readCannedResponseText =
        this.existingWorkOrderDetails.CannedResponseText;
      // end

      if (this.existingWorkOrderDetails.CannedResponseId) {
        this.isDisabledCannedResponseText = false;
      }

      this.longitude = this.existingWorkOrderDetails.Longitude;
      this.existingLatitude = this.latitude;
      this.existingLongitude = this.longitude;
      this.workOrderType = this.existingWorkOrderDetails.WorkOrderType;
      this.WorkId = this.existingWorkOrderDetails.WorkId;
      this.isEditMode = !!this.existingWorkOrderDetails.WorkId;
      this.isMapDisabledMode = !this.existingWorkOrderDetails.WorkId;
      this.workOrderIdTemp =
        'WCD' +
        this.WorkIdVal +
        ' - ' +
        moment(this.existingWorkOrderDetails.CreatedDate).format('D MMMM YYYY');
      this.DynamicFieldsList = this.existingWorkOrderDetails.DynamicFields;
      this.existingWorkOrderDetails.UploadedPhotoBeforeIds
        ? this.UploadedPhotoBeforeIdsTemp.push(
          this.existingWorkOrderDetails.UploadedPhotoBeforeIds
        )
        : (this.UploadedPhotoBeforeIdsTemp = []);
      this.existingWorkOrderDetails.UploadedPhotoAfterIds
        ? this.UploadedPhotoAfterIdsTemp.push(
          this.existingWorkOrderDetails.UploadedPhotoAfterIds
        )
        : (this.UploadedPhotoAfterIdsTemp = []);

      this.existingWorkOrderDetails.uploadBeforeDocument
        ? (this.uploadBeforeDocumentComments =
          this.existingWorkOrderDetails.uploadBeforeDocument)
        : (this.uploadBeforeDocumentComments = []);
      this.existingWorkOrderDetails.uploadAfterDocument
        ? (this.uploadAfterDocumentComments =
          this.existingWorkOrderDetails.uploadAfterDocument)
        : (this.uploadAfterDocumentComments = []);
      this.getCannedResponseList(this.existingWorkOrderDetails.WorkCategory);
      this.getBeforeFile(this.UploadedDocPathsBefore);

      this.getAfterFile(this.UploadedDocPathsAfter);
      this.getAfterCommentDocuments(this.uploadAfterDocumentComments);
      this.getBeforeCommentDocuments(this.uploadBeforeDocumentComments);
    }
  }

  getExistingWoComment(data) {
    if (this.existingWorkOrderDetails) {
      this.workOrderForm.patchValue({
        uploadBeforeDocument: data.Data.uploadBeforeDocument
          ? data.Data.uploadBeforeDocument
          : '',
        uploadAfterDocument: data.Data.uploadAfterDocument
          ? data.Data.uploadAfterDocument
          : ''
      });
      this.getAfterCommentDocuments(this.uploadAfterDocumentComments);
      this.getBeforeCommentDocuments(this.uploadBeforeDocumentComments);
    }

  }

  getBeforeCommentDocuments(uploadBeforeDocumentComments: commentDoc[]) { // ! here
    uploadBeforeDocumentComments.forEach((item) => {
      this.uploadBeforeCommentData.push({
        uploadId: item.uploadId,
        uploadComment: item.uploadComment
      });
    });
  }

  getAfterCommentDocuments(uploadAfterDocumentComments: commentDoc[]) {
    uploadAfterDocumentComments.forEach((item) => {
      this.uploadAfterCommentData.push({
        uploadId: item.uploadId,
        uploadComment: item.uploadComment
      });
    });
  }

  onChangeOfWorkCategory(selectedWorkCategory: any) {
    selectedWorkCategory = selectedWorkCategory.split(':')[1].trim();
    this.getCannedResponseList(selectedWorkCategory);
  }

  onDelete(item) {
    this.DynamicFieldsList = this.DynamicFieldsList.filter(
      (x) => x.Values !== item
    );
    this.workOrderForm.patchValue({
      DynamicFields: this.DynamicFieldsList
    });
  }

  getBeforeFile(fileArray) {
    const result = fileArray;
    if (this.uploadBeforePhotoFilePath.length) {
      this.uploadBeforePhotoFilePath = [];
    }
    result.map((element) => {
      let fileExtension = element['uploadDocument'].split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadBeforePhotoFilePath.push({
        filePath: element.uploadDocument,
        extension: fileExtension,
        uploadComment: element.uploadComment,
        uploadDate: element.UploadDate
      });

    });
    const unique = [];
    const distinct = [];
    for (let i = 0; i < this.uploadBeforePhotoFilePath.length; i++) {
      if (!unique[this.uploadBeforePhotoFilePath[i].filePath]) {
        distinct.push({
          filePath: this.uploadBeforePhotoFilePath[i].filePath,
          extension: this.uploadBeforePhotoFilePath[i].extension,
          uploadComment: this.uploadBeforePhotoFilePath[i].uploadComment,
          uploadDate: this.uploadBeforePhotoFilePath[i].uploadDate
        });
        unique[this.uploadBeforePhotoFilePath[i].filePath] = 1;
      }
    }
  }

  getAfterFile(fileArray) {
    const result = fileArray;
    if (this.uploadAfterPhotoFilePath.length) {
      this.uploadAfterPhotoFilePath = [];
    }
    result.forEach((element) => {
      let fileExtension = element['uploadDocument'].split('.');
      fileExtension = fileExtension[fileExtension.length - 1];
      this.uploadAfterPhotoFilePath.push({
        filePath: element.uploadDocument,
        extension: fileExtension,
        uploadComment: element.uploadComment,
        uploadDate: element.UploadDate
      });
    });
    const unique = [];
    const distinct = [];
    for (let i = 0; i < this.uploadAfterPhotoFilePath.length; i++) {
      if (!unique[this.uploadAfterPhotoFilePath[i].filePath]) {
        distinct.push({
          filePath: this.uploadAfterPhotoFilePath[i].filePath,
          extension: this.uploadAfterPhotoFilePath[i].extension,
          uploadComment: this.uploadAfterPhotoFilePath[i]?.uploadComment,
          uploadDate: this.uploadAfterPhotoFilePath[i]?.uploadDate
        });
        unique[this.uploadAfterPhotoFilePath[i].filePath] = 1;
      }
    }

    this.uploadAfterPhotoFilePath = distinct.sort((a, b) =>
      a.extension > b.extension ? 1 : -1
    );
  }

  getWorkCategoryList() {
    this.commonService.getWorkCategoryList().subscribe((res: APIResponse) => {
      this.workCategoryList = res.Data;
    });
  }

  getCannedResponseList(workCategory) {
    this.commonService
      .getCannedResponseList(workCategory)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: APIResponse) => {
        this.cannedResponseList = res.Data || [];
      });
  }

  getJobListBySupervisorId() {
    this.loaderService.isLoading.next(true);
    this.commonService
      .getJobListBySupervisor()
      .pipe(finalize(() => this.loaderService.isLoading.next(false)))
      .subscribe((res: APIResponse) => {
          if (res.Data && res.Success && res.StatusCode === 200) {
            this.jobListDetails = res.Data;
          }
        }
      );
  }

  getAllJobListDetails() {
    this.loaderService.isLoading.next(true);
    const serachText = '';
    this.commonService
      .getAllJobList(serachText)
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => this.loaderService.isLoading.next(false)))
      .subscribe((res: APIResponse) => {
        this.jobListDetails = res.Data;
        if (this.existingWorkOrderDetails &&
          this.existingWorkOrderDetails.CustomerId) {
          this.getSelectedJobs(this.existingWorkOrderDetails.CustomerId);
        }
      });
  }

  getSupervisorListByJobId() {
    if (this.jobId) {
      this.loaderService.isLoading.next(true);
      if (this.authService?.currentLoginUserValue.Role === 'Admin') {
        this.commonService
          .getSupervisors(this.jobId)
          .pipe(finalize(() => this.loaderService.isLoading.next(false)))
          .subscribe((res: APIResponse) => {
            if (res && res.Data && res.Success) {
              this.getClientListByJobId(this.jobId);
              this.supervisorList = res.Data.map((item) => {
                return {
                  ID: item.ID,
                  Text: item.Text.trim(),
                  RoleCode: item.RoleCode
                };
              });
              this.getSupervisorsListAndPatchValues(this.supervisorList, !this.WorkId);
            }
          });
      } else {
        this.commonService
          .getSupervisorAndFAListByJobId(this.jobId)
          .pipe(takeUntil(this.destroy$), finalize(() => this.loaderService.isLoading.next(false)))
          .subscribe((res: APIResponse) => {
            if (res && res.Data && res.Success) {
              this.getClientListByJobId(this.jobId);
              this.supervisorList = res.Data.map((item) => {
                return {
                  ID: item.ID,
                  Text: item.Text.trim(),
                  RoleCode: item.RoleCode
                };
              });
              this.getSupervisorsAndFAListAndPatchValues();
            }
          });
      }
    }
  }

  getSupervisorsListAndPatchValues(supervisorList: Role[], admin: boolean): void {
    if (this.isEditMode) {
      this.workOrderForm.patchValue({
        SuperVisorId: this.assignedSupervisors.map(i => i.Id)
      })
      return
    }
    const firstSupervisor = supervisorList.find((item) => item.RoleCode === 'S');
    const defaultAssignee = firstSupervisor ? firstSupervisor : null;
    const supervisorIds: number[] = supervisorList.map((item: Role) => {
      if (item.RoleCode === 'S') {
        return item.ID;
      }
    });
    if (defaultAssignee && admin) {
      this.workOrderForm.patchValue({
        SuperVisorName: defaultAssignee.Text.trim(),
        SuperVisorId: supervisorIds
      });
    }
  }

  getSupervisorsAndFAListAndPatchValues(): void {
    const firstSupervisor = this.supervisorList.find((item) => item.RoleCode === 'S');
    const defaultAssignee = firstSupervisor ? firstSupervisor : null;
    if (defaultAssignee && !this.WorkId) {
      this.workOrderForm.patchValue({
        SuperVisorName: defaultAssignee.Text.trim(),
        SuperVisorId: defaultAssignee.ID
      });
    }
  }

  getSupervisorListByCustomerId(customerId) {
    if (customerId) {
      this.commonService
        .getSupervisorAndFAListByJobId(customerId)
        .pipe(takeUntil(this.destroy$), finalize(() => this.loaderService.isLoading.next(false)))
        .subscribe((res: APIResponse) => {
          if (res && res.Data && res.Success) {
            this.getClientListByJobId(this.jobId);
            this.supervisorList = res.Data.map((item) => {
              return {
                ID: item.ID,
                Text: item.Text.trim(),
                RoleCode: item.RoleCode
              };
            });
            this.getSupervisorsListAndPatchValues(this.supervisorList, true);
          }
        });
    }
  }

  getClientListByJobId(jobId: number) {
    if (this.isForClient) {
      this.clientList = [];
      this.loaderService.isLoading.next(true);
      this.userService.getListOfUsers('', '', '', '', 'Client')
        .pipe(
          takeUntil(this.destroy$),
          finalize(() => this.loaderService.isLoading.next(false)))
        .subscribe((data: any) => {
          const clientsWithJobs = data.Data.profiles.filter((client: ClientUser) => client.Jobs?.length);
          clientsWithJobs.forEach((client: ClientUser) => {
            client.Jobs.forEach((item) => {
              if (item.Id === Number(jobId)) {
                this.clientList.push(client);
              }
            });
          });
          this.loaderService.isLoading.next(false);
        }, () => this.loaderService.isLoading.next(false));
    }
  }

  onSelectSupervisor(event: { target: { value: any } }): void {
    const supervisorRoleCode = this.supervisorList?.find(
      (x) => x.ID === Number(event.target.value)
    );
    if (supervisorRoleCode?.RoleCode === 'S') {
      this.workOrderForm.patchValue({
        SuperVisorId: supervisorRoleCode.ID,
        SuperVisorName: supervisorRoleCode.Text,
        FieldAgentId: null,
        WorkStatusText: 'Pending'
      });
    } else {
      this.workOrderForm.patchValue({
        FieldAgentId: supervisorRoleCode?.ID,
        FieldAgentName: supervisorRoleCode?.Text,
        WorkStatusText: 'WorkInProgress'
      });
    }
  }

  getSelectedJobs(event) {
    if (!event.target || event.target.value == 'null') {
      this.workOrderForm.setValue({
        ...this.workOrderForm.value,
        SuperVisorId: null
      });
      this.supervisorList = [];
      return;
    }
    this.currentRoute = false;
    this.isMapDisabledMode = true;
    this.customerId = event.target?.value ? event.target?.value : event;
    if (this.customerId) {
      const selectedJobs = this.jobListDetails.find(
        (x) => x.Id == this.customerId
      );
      this.selectedJobName = selectedJobs ? selectedJobs.Name : '';
      this.onSelectedJobAdd.emit(this.selectedJobName);
      if (
        this.workOrderForm.value.Address &&
        this.currentUserRole !== ConstantsService.User_Role.Admin
      ) {
        this.modalRef = this.modalService.show(this.templateConfirm, {
          class: 'modal-md'
        });
        this.jobId = this.customerId;
      } else {
        this.loaderService.isLoading.next(true);
        this.commonService
          .getAddressByCustomer(this.customerId)
          .pipe(
            takeUntil(this.destroy$),
            finalize(() => this.loaderService.isLoading.next(false)))
          .subscribe((res: APIResponse) => {
            this.city = res.Data?.City;
            this.zipCode = res.Data?.ZipCode;
          });
        this.jobId = this.customerId;
        this.prevCustomerId = this.customerId;
        this.readCustomerId = this.selectedJobName;
        this.workOrderForm.setValue({
          ...this.workOrderForm.value,
          CustomerName: this.selectedJobName,
          CreatedBy: this.isForClient ? this.workOrderForm.value.CreatedBy
            : this.authService.currentLoginUserValue.Id
        });
      }
      this.getFieldAgentBySupervisorId(this.customerId);
      this.getSupervisorListByJobId();
    }
  }

  addClientContactInfo(): void {
    this.ContactForm.patchValue({
      ContactFirstName: this.client.FirstName,
      ContactLastName: this.client.LastName,
      ContactEmail: this.client.Email
    });
  }

  confirmChangeLocation() {
    if (
      this.currentUserRole === ConstantsService.User_Role.Admin ||
      this.currentUserRole === ConstantsService.User_Role.SuperVisor ||
      this.currentUserRole === ConstantsService.User_Role.FieldAgent
    ) {
      this.prevCustomerId = this.jobId;
      this.workOrderForm.patchValue({
        CustomerName: this.readCustomerId,
        CustomerId: this.prevCustomerId
      });
      this.commonService
        .getAddressByCustomer(this.jobId)
        .pipe(takeUntil(this.destroy$))
        .subscribe((res: APIResponse) => {
          this.city = res.Data?.City;
          this.zipCode = res.Data?.ZipCode;
          this.workOrderForm.patchValue({
            Address: ``
          });
        });
      this.getSupervisorListByJobId();
    } else {
      this.jobId = this.customerId;
      this.prevCustomerId = this.customerId;
      this.readCustomerId = this.selectedJobName;

      this.workOrderForm.patchValue({
        CustomerName: this.selectedJobName,
        CreatedBy: this.isForClient ? this.workOrderForm.value.CreatedBy
          : this.authService.currentLoginUserValue.Id
      });
      this.workOrderForm.patchValue({
        Address: ''
      });

    }
    this.modalRef.hide();
  }

  cancelLocationChange() {
    if (this.currentUserRole === ConstantsService.User_Role.Admin) {
      this.workOrderForm.patchValue({
        CustomerName: this.readCustomerId,
        CustomerId: this.prevCustomerId
      });
      this.jobId = this.prevCustomerId;
    } else {
      this.workOrderForm.patchValue({
        CustomerId: this.prevCustomerId,
        CustomerName: this.readCustomerId,
        CreatedBy: this.isForClient ? this.workOrderForm.value.CreatedBy
          : this.authService.currentLoginUserValue.Id
      });
    }

    this.modalRef.hide();
  }

  onAddMoreFieldsResponse(response) {
    this.DynamicFieldsList.push(response);
    this.workOrderForm.patchValue({
      DynamicFields: this.DynamicFieldsList
    });
  }

  createWorkOrder(btnText?: string) {
    setTimeout(() => {
      this.buttonText = btnText;
      this.onAddressAdd.emit(this.workOrderForm.controls.Address.value);
      this.submitted = true;
      if (
        btnText === this.closeWorkOrder &&
        this.currentUserRole === ConstantsService.User_Role.SuperVisor
      ) {
        this.workOrderForm
          .get('CannedResponseName')
          .setValidators([Validators.required]);
        this.workOrderForm.get('CannedResponseName').updateValueAndValidity();
        this.workOrderForm
          .get('CannedResponseId')
          .setValidators([Validators.required]);
        this.workOrderForm.get('CannedResponseId').updateValueAndValidity();
        this.workOrderForm.patchValue({
          WorkStatusText: 'Completed'
        });
      } else {
        this.workOrderForm.get('CannedResponseName').clearValidators();
        this.workOrderForm.get('CannedResponseName').updateValueAndValidity();
        this.workOrderForm.get('CannedResponseId').clearValidators();
        this.workOrderForm.get('CannedResponseId').updateValueAndValidity();
      }

      if (this.woExist) {
        this.bindValueInField();
      }
      this.workOrderForm.patchValue({
        EndDate: this.workOrderForm.value.CompletedBy,
        SaveBtnText: btnText ? btnText : ''
      });

      if (this.workOrderForm.value.UploadedPhotoBeforeIds.length === 0) {
        this.workOrderForm.patchValue({
          UploadedPhotoBeforeIds: ''
        });
      }
      if (this.workOrderForm.value.UploadedPhotoAfterIds.length === 0) {
        this.workOrderForm.patchValue({
          UploadedPhotoAfterIds: ''
        });
      }

      if (btnText === 'reopen') {
        this.workOrderForm.get('Notes').setValidators([Validators.required]);
        this.workOrderForm.get('Notes').updateValueAndValidity();
        const isTrue = confirm('Are you sure to re-open this Work Order?');
        if (!isTrue) {
          this.workOrderForm.get('Notes').clearValidators();
          this.workOrderForm.get('Notes').updateValueAndValidity();
          return;
        }
      } else {
        this.workOrderForm.get('Notes').clearValidators();
        this.workOrderForm.get('Notes').updateValueAndValidity();
      }
      if (btnText === 'updateWorkOrderStatus' || btnText === 'reopen') {
        this.workOrderForm.value.WorkStatusText = 'WorkInProgress';
      }

      this.saveContactInfo();
      if (this.workOrderForm.invalid || !this.checkContactFormValidity()) {
        this.contactFormIsValid = this.checkContactFormValidity();
        this.contactInfoRequiredEl?.nativeElement.scrollIntoView({ behavior: 'smooth' });
        return;
      }

      if (this.workOrderForm.value.Notes) {
        this.workOrderForm.patchValue({ NotesCreatedBy: this.currentUser.Id });
      }

      if (this.workOrderForm.value.WorkStatusText !== 'Schedule' && btnText === 'saveWithSchedule') {
        this.workOrderForm.value.StartDate = moment(
          this.workOrderForm.value.CompletedBy).add(1, 'days'); // changed due to date in the google shedule is not the same as here
      } else {
        this.workOrderForm.value.StartDate = moment(
          this.workOrderForm.value.StartDate
        ).add(1, 'days'); // changed due to date in the google shedule is not the same as here
      }

      this.workOrderForm.value.EndDate = moment(this.workOrderForm.value.EndDate)
        .add(1, 'days');

      this.workOrderForm.value.CompletedBy = moment(this.workOrderForm.value.CompletedBy)
        .add(1, 'days');
      if (this.isForClient && this.client !== undefined) {
        this.addClientContactInfo();
      }

      if (this.workOrderForm.value.SuperVisorId !== null && this.workOrderForm.value.SuperVisorId.length) {
        const assigneeId = this.workOrderForm.value.SuperVisorId[0];
        if (this.supervisorList) {
          const assignee: Role = this.supervisorList.find((assignee) => assignee.ID === assigneeId);
          if (assignee && assignee.RoleCode === 'F') {
            this.workOrderForm.patchValue({
              FieldAgentId: assignee.ID,
              FieldAgentName: assignee.Text,
              SuperVisorId: '',
              SuperVisorName: ''
            });
          }
        }
      }

      if (this.currentUserRole === ConstantsService.User_Role.Admin) {
        this.workOrderForm.patchValue({
          SuperVisorId: ''
        });
      }

      if (this.contactAddress) {
        this.ContactForm.patchValue({
          ContactAddress: this.contactAddress
        });
      }
      Object.assign(this.workOrderForm.value, this.ContactForm.value);
      this.onWorkOrderUpdateResponse.emit(this.workOrderForm.value);
      this.updateAdditionalLabels(this.labelDataToUpdate);

      if (btnText === Notes || btnText === Reopen) {
        this.WorkOrderNotesDetail.unshift({
          NotesText: this.workOrderForm.value.Notes,
          CreatedBy: this.currentUser.Name,
          CreatedDate: moment().subtract(30, 'minutes')
        });
        this.workOrderForm.patchValue({
          Notes: ''
        });
      }
    }, 400);
  }

  gmapResponseUpdate(eventElement: { latitude: any; longitude: any }) {
    if (eventElement.latitude) {
      this.workOrderForm.patchValue({
        Latitude: eventElement.latitude,
        Longitude: eventElement.longitude
      });
      this.getAddress(eventElement.latitude, eventElement.longitude);
      this.validAddress = true;
    } else {
      this.validAddress = false;
      this.handleCurrentLocation();
    }
  }

  onAddressChange(address: any) {
    let ContactAddress: string;
    if (address.formatted_address) {
      ContactAddress = address.formatted_address;
      this.contactAddress = address.formatted_address;
    } else {
      ContactAddress = address;
      this.contactAddress = address;
    }
    this.ContactForm.setValue({
      ...this.ContactForm.value,
      ContactAddress
    });
  }

  gmapResponseForCityName(eventElement: {
    latitude: number;
    longitude: number;
    address: string;
  }) {
    if (eventElement.latitude) {
      this.workOrderForm.patchValue({
        Latitude: eventElement.latitude,
        Longitude: eventElement.longitude
      });
      this.validAddress = true;
    } else {
      this.validAddress = false;
      this.handleCurrentLocation();
    }
  }

  fileUploadResponseBefore(response: FileUploadResponse): void {
    const multipleImages = response.Data.DocumentID.split(',');
    if (multipleImages.length > 0) {
      multipleImages.forEach((img: string) => {
        this.UploadedPhotoBeforeIdsTemp.push(img);
        this.uploadBeforeId = img;
        this.uploadBeforeDocumentComments.push({
          uploadId: this.uploadBeforeId,
          uploadComment: this.uploadBeforeComment
        });
      });
    }

    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    let tempFilesArr: uploadPhotoFilePath[];
    tempFilesArr = fileUploadResponse ? this.uploadBeforePhotoFilePath.concat(fileUploadResponse) : [];
    this.uploadBeforePhotoFilePath = tempFilesArr;


    this.saveBeforeComment(this.uploadBeforeComment, this.uploadBeforeId);
  }

  fileUploadResponseAfter(response: FileUploadResponse): void {
    const multipleImages = response.Data.DocumentID.split(',');
    if (multipleImages.length > 0) {
      multipleImages.forEach((img: string) => {
        this.UploadedPhotoAfterIdsTemp.push(img);
        this.uploadAfterId = img;
        this.uploadAfterDocumentComments.push({
          uploadId: this.uploadAfterId,
          uploadComment: this.uploadAfterComment
        });
      });
    }
    const fileUploadResponse = this.methodsForAllRoles.fileUploadResponse(response);
    let tempFilesArr: uploadPhotoFilePath[];
    tempFilesArr = fileUploadResponse ? this.uploadAfterPhotoFilePath.concat(fileUploadResponse) : [];
    this.uploadAfterPhotoFilePath = tempFilesArr;
    this.saveAfterComment(this.uploadAfterComment, this.uploadAfterId);
  }

  backToPrevious() {
    this.location.back();
  }

  getCannedResponseText(cannedId: string) {
    if (cannedId == 'null') {
      this.isDisabledCannedResponseText = true;
      this.workOrderForm.patchValue({
        CannedResponseText: ''
      });
    } else if (cannedId) {
      this.isDisabledCannedResponseText = false;
      this.getCannedResponseTextName(cannedId);
      this.actionTaken.emit(this.CannedResponseName);
      if (cannedId.split(':')[1].trim() === '21') {
        this.workOrderForm
          .get('CannedResponseText')
          .setValidators([Validators.required]);
        this.workOrderForm.get('CannedResponseText').updateValueAndValidity();


      } else {
        this.workOrderForm.get('CannedResponseText').clearValidators();
        this.workOrderForm.get('CannedResponseText').updateValueAndValidity();
      }
    }

    if (cannedId === null || cannedId === 'null') {
      this.btnText = 'Save';
      this.workOrderForm.patchValue({
        WorkStatusText: 'WorkInProgress'
      });
    } else if (this.currentUserRole === 'FieldAgent') {
      this.btnText = this.closeWorkOrder;
      const cannedResponse = this.cannedResponseList.find(
        (item) => item.ID === Number(cannedId.split(' ')[1])
      );
      this.CannedResponseName = cannedResponse.Text;

      this.CannedResponseId = cannedResponse.ID;
      this.workOrderForm.patchValue({
        CannedResponseName: cannedResponse.Text,
        WorkStatusText: 'UnderReview'
      });
    } else {
      this.btnText = this.closeWorkOrder;
      this.showScheduleDate = false;
      const cannedResponse = this.cannedResponseList.find(
        (item) => item.ID === Number(cannedId.split(' ')[1])
      );
      this.CannedResponseName = cannedResponse.Text;
      this.CannedResponseId = cannedResponse.ID;
      this.workOrderForm.patchValue({
        CannedResponseName: cannedResponse.Text,
        WorkStatusText: 'Completed'
      });
    }
  }

  getCannedResponseTextName(cannedId) { // ! change this function name
    if (cannedId !== null && !Number.isInteger(cannedId) && this.cannedResponseList.length) {
      this.CannedResponseName = this.cannedResponseList.find(
        (item) => item.ID == cannedId.split(':')[1]).Text;
      this.commonService.setStatusTextSubjectMethod(true);
    }
  }

  workOrderStatusChange(event) {
    if (event.target.value === 'Completed') {
      this.showScheduleDate = false;
      this.btnText = this.closeWorkOrder;
      this.workOrderForm
        .get('CannedResponseId')
        .setValidators([Validators.required]);
      this.workOrderForm.get('CannedResponseId').updateValueAndValidity();
    } else if (event.target.value === 'Schedule') {
      this.showScheduleDate = true;
      this.btnText = 'Save';
    } else {
      this.showScheduleDate = false;
      this.btnText = 'Save';
      this.workOrderForm.get('CannedResponseId').clearValidators();
      this.workOrderForm.get('CannedResponseId').updateValueAndValidity();
    }
  }

  onFieldAgentDetails(e: { target: any }) {
    if (e.target.value === 'null') {
      this.workOrderForm.patchValue({
        FieldAgentId: null
      });
      this.workOrderForm
        .get('FieldAgentId')
        .setValidators([Validators.required]);
      this.workOrderForm.get('FieldAgentId').updateValueAndValidity();
    } else {
      const fieldAgentDetails = this.fieldAgentListDetails.find(
        (x) => x.Id === Number(e.target.value)
      );
      this.workOrderForm.patchValue({
        FieldAgentId: e.target.value,
        FieldAgentName: fieldAgentDetails ? fieldAgentDetails.Name : '',
        TokenId: fieldAgentDetails ? fieldAgentDetails?.TokenId : ''
      });
      this.workOrderForm.get('FieldAgentId').clearValidators();
      this.workOrderForm.get('FieldAgentId').updateValueAndValidity();
    }
  }

  saveContactInfo() {
    this.createOriginalContactForm();
    this.checkContactForm();
    this.setOriginalFormValues();
    this.contactFormIsValid = this.checkContactFormValidity();
    if (this.modalRef !== undefined && this.contactFormIsValid &&
      this.ContactForm.value.ContactFirstName !== '' &&
      this.ContactForm.value.ContactLastName !== '' &&
      this.ContactForm.value.ContactEmail !== '' &&
      this.ContactForm.value.ContactPhoneNumber !== '') {
      this.modalRef.hide();
    }
  }

  checkContactForm(): void {
    this.contactInfoSubmit = true;

    this.ContactForm
      .get('ContactFirstName')
      .setValidators([Validators.required]);
    this.ContactForm.get('ContactFirstName').updateValueAndValidity();

    this.ContactForm
      .get('ContactLastName')
      .setValidators([Validators.required]);
    this.ContactForm.get('ContactLastName').updateValueAndValidity();

    this.ContactForm
      .get('ContactEmail')
      .setValidators([customEmailValidator(), Validators.required]);
    this.ContactForm.get('ContactEmail').updateValueAndValidity();

    this.ContactForm
      .get('ContactPhoneNumber')
      .setValidators([Validators.required,
        Validators.minLength(10),
        Validators.maxLength(12),
        Validators.pattern(/^[0-9\s!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]+$/)
      ]);
    this.ContactForm.get('ContactPhoneNumber').updateValueAndValidity();
  }

  setOriginalFormValues(): void {
    this.originalWorkOrderForm.patchValue({
      ContactFirstName: this.ContactForm?.value?.ContactFirstName || '',
      ContactLastName: this.ContactForm?.value?.ContactLastName || '',
      ContactEmail: this.ContactForm.value.ContactEmail || '',
      AdditionalContactEmail: this.ContactForm.value.AdditionalContactEmail || '',
      ContactPhoneNumber: this.ContactForm.value.ContactPhoneNumber || ''
    });
  }

  setWorkOrderFormValues(): void {
    this.ContactForm.patchValue({
      ContactFirstName: this.originalWorkOrderForm?.value.ContactFirstName || '',
      ContactLastName: this.originalWorkOrderForm?.value.ContactLastName || '',
      ContactEmail: this.originalWorkOrderForm?.value.ContactEmail || '',
      ContactPhoneNumber: this.originalWorkOrderForm?.value.ContactPhoneNumber || ''
    });
  }

  showContactInfoModal() {
    this.modalRef = this.modalService.show(this.contactInfo, {
      class: 'modal-md'
    });
    // this.checkContactForm();
    this.modalService.onHide
      .subscribe((ev) => {
        if (ev === 'backdrop-click') {
          this.setWorkOrderFormValues();
        }
      });
  }

  confirmMySelf() {
    this.workOrderForm.controls.CreatedBy.setValidators(null);
    this.workOrderType = 'resident';
    this.workOrderForm.patchValue({
      isForResident: true
    });
    this.isForResident = true;
    this.modalRef.hide();
  }

  // For admin role only
  createWorkOrderClient() {
    this.isForClient = true;
    this.workOrderForm.controls.CreatedBy.setValidators(Validators.required);
    this.workOrderForm.patchValue({
      isForClient: true
    });
    this.modalRef.hide();
  }

  setClientValue(e: any) {
    const clientId = e.target?.value;
    this.client = this.clientList.find((client: ClientUser) => Number(client.UserId) === Number(clientId));
    this.setContactInfo();
  }

  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (this.chips.length < 10) {
      const formArray: FormArray = this.ContactForm.get('AdditionalContactEmail') as FormArray;
      const additionalFormControl: FormControl = new FormControl(value, [customEmailValidator(), Validators.required]);

      if (additionalFormControl.valid) {
        this.chips.push(value);
        formArray.push(additionalFormControl);
        event.chipInput!.clear();
        this.contactForm.AdditionalContactEmail.setErrors({ invalidEmail: false });
      } else {
        formArray.setErrors({ invalidEmail: true });
      }
    }
  }

  removeChip(chip: string): void {
    const index = this.chips.indexOf(chip);
    if (index !== -1) {
      this.chips.splice(index, 1);
    }
  }

  ngOnDestroy() {
    this.getBeforeFile([]);
    this.getAfterFile([]);
    this.destroy$.next(true);
    this.destroy$.complete();
    this.modalRef !== undefined && this.modalRef.hide();
  }

  private getJobListByRole(): void {
    if (
      this.currentUserRole === ConstantsService.User_Role.Admin ||
      this.currentUserRole === ConstantsService.User_Role.SuperVisor
    ) {
      this.isDisabledStatus = false;
    }
    if (
      this.currentUserRole &&
      this.currentUserRole === ConstantsService.User_Role.Admin
    ) {
      this.getAllJobListDetails();
    } else {
      this.getJobListBySupervisorId();
    }
  }

  private getUrlEnd(): void {
    const urlEnd: string[] = this.location.path().split('/');
    const hasCreateWorkOrder: boolean = urlEnd.some((item: string) => item === 'create-work-order');
    if (hasCreateWorkOrder) {
      this.setContactInfo();
    }
  }

  private listenRouterEvents(): void {
    this.router.events
      .pipe(filter((event: Event) => event instanceof NavigationEnd))
      .pipe(takeUntil(this.destroy$))
      .subscribe((event: NavigationEnd) => {
        this.currentRoute = event.url.endsWith('create-work-order');
        if (this.currentRoute) {
          this.readAddress = '';
          this.isMapDisabledMode = false;
        }
      });
  }

  private setContactInfo() {
    if (this.currentUserRole === ConstantsService.User_Role.SuperVisor || this.currentUserRole === ConstantsService.User_Role.FieldAgent) {
      const userFromLocal = JSON.parse(localStorage.getItem('currentUser'));
      this.setContactFormDefaultValues(userFromLocal);
    } else if (this.currentUserRole === ConstantsService.User_Role.Admin && this.isForClient) {
      this.setContactFormDefaultValues(this.client);
    }
  }

  private setContactFormDefaultValues(userDetails: ClientUser): void {
    this.ContactForm.patchValue({
      ContactFirstName: userDetails.FirstName,
      ContactLastName: userDetails.LastName,
      ContactPhoneNumber: userDetails.PhoneNumber || userDetails.Phone,
      ContactEmail: userDetails.Email,
      ContactAddress: userDetails.Address
    });
    this.setOriginalFormValues();
  }

  private getLocationLatitudeLong() {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder();

      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef?.nativeElement
      );

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          // set latitude, longitude and zoom
          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 30;
        });
      });
    });
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (
          this.latitude !== this.existingLatitude &&
          this.longitude !== this.existingLongitude
        ) {
          this.latitude = position.coords.latitude;
          this.longitude = position.coords.longitude;
          this.zoom = 30;
          this.getAddress(this.latitude, this.longitude);
        }
      });
    }
  }

  private checkContactFormValidity(): boolean {
    return (
      this.ContactForm.controls.ContactFirstName.valid &&
      this.ContactForm.controls.ContactLastName.valid &&
      this.ContactForm.controls.ContactEmail.valid &&
      this.ContactForm.controls.ContactPhoneNumber.valid);
  }

  private createOriginalContactForm() {
    this.originalWorkOrderForm = this.formBuilder.group({
      ContactFirstName: [''],
      ContactLastName: [''],
      ContactPhoneNumber: [''],
      ContactEmail: ['', [customEmailValidator()]],
      AdditionalContactEmail: this.formBuilder.array([])
    });
  }

  unCheck(val: any) {
    this.workOrderForm.patchValue({ SuperVisorId: null });
    this.workOrderForm.patchValue({ SuperVisorId: [val.ID] });
  }

  private getJobLabels(customerId: number) {
    this.jobService.getJob(customerId)
      .subscribe((res) => {
        this.job = res;
        this.createDynamicLabelControls();
      });
  }

  createDynamicLabelControls(): void {
    if (this.job && this.job.Labels) {
      this.job.Labels.forEach(label => {
        const controlName = 'Label' + label.LabelId;
        if (!this.workOrderForm.contains(controlName)) {
          this.workOrderForm.addControl(controlName, new FormControl(null));
        }
      });
      this.getAdditionalLabels(this.WorkIdVal);
    }
  }

  onLabelChange(event: any, LabelId: number): void {
    const selectedValue = event.target.value;
    const optionId = Number(selectedValue.split(':')[1]);
    const controlName = 'Label' + LabelId;
      if (this.workOrderForm.contains(controlName)) {
        this.labelDataToUpdate.Labels
          .push({
          LabelId,
          ValueId: optionId
        });
        this.labelDataToUpdate.WorkOrderId = Number(this.WorkId);
      }
  }

  updateAdditionalLabels(woLabel: any) {
    this.jobService
      .updateAdditionalLabels(woLabel)
      .subscribe();
  }

  private getAdditionalLabels(taskId: number) {
    this.jobService.getAdditionalLabels(taskId)
      .subscribe((res: Label[]) => {
        this.job.Labels = res;
        this.setLabelControlValues();
      });
  }

  setLabelControlValues(): void {
    if (this.job && this.job.Labels) {
      this.job.Labels.forEach(label => {
        const controlName = 'Label' + label.LabelId;
        if (this.workOrderForm.contains(controlName)) {
          const selectedValue = label.Values.find(value => value.Selected);
          if (selectedValue) {
            this.workOrderForm.get(controlName).setValue(selectedValue.ValueId);
          }
        }
      });
    }
  }

}
